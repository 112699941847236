/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/Da_Vinci_Vitruve_Luc_Viatour-350x476.jpg",
    alt: ""
  }), "Se cuenta que el Dios emperador Leto II necesitó más de 3.000 años para, mediante su programa de selección artificial, crear un ser humano invisible a la presciencia, ", React.createElement(_components.a, {
    href: "http://dune.wikia.com/wiki/Siona_Atreides"
  }, "Siona Atreides"), ". Esto quiere decir que ella y sus descendientes tenían la habilidad de esconder sus intenciones y evitar que aquellos que tenían la capacidad de anticipar los movimientos del resto, lo hicieran con ellos. Esta cualidad permite a la humanidad no volver a sufrir el yugo de los tiranos prescientes y es uno de los legados más importantes de Leto II."), "\n", React.createElement(_components.p, null, "Todo esto es obviamente ficción, relatada en las novelas de Dune de Frank Herbert, la realidad es mucho más sorprendente. En la realidad un simple humano, pero con la ventaja de millones de años de selección natural y una inteligencia brillante, consiguió el año pasado demostrarnos que no es necesario la llegada de ningún superhombre, la simple pero poderosa inteligencia humana ya puede ganar al tirano que mediante su brutal capacidad de cálculo conoce todas las probabilidades de que cualquier suceso ocurra y puede ver el futuro de la misma forma que nosotros podemos otear el horizonte."), "\n", React.createElement(_components.p, null, "Así como Siona «sorprendía» a su emperador, ", React.createElement(_components.a, {
    href: "https://lasindias.blog/el-dia-en-que-lee-sedol-nos-enseno-a-vivir-en-el-siglo-xxi"
  }, "Lee Sedol sorprendió a la maquinaria de Google"), ", arrancándole una victoria, solamente una vez, es una habilidad que hemos entrenado poco, pero Siona sólo tuvo que sorprender realmente una vez al Dios emperador."), "\n", React.createElement(_components.p, null, "Quien sabe lo que nos deparará el futuro, lo que es seguro es que nadie nos tendrá que subyugar durante cuatro milenios para conseguir esta habilidad, ya la llevamos dentro, la habilidad de sorprender."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
